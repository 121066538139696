
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { login, userInfo, userMenus, ossToken } from '@/api/user';
// import { setItem } from '@/components/js/localStorage';
import Base64Utils from '@/utils/Base64Utils'  //引入
import { useStore } from "vuex";
import { ACCESS_TOKEN, PERMISSION, USER_INFO,AUTH_ROLE } from '@/store/mutation-types'
import { LocalStorageService } from "@/components/ts/localStorage";
import STATUS from "@/components/model/enum";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
var base1 = new Base64Utils();
export default defineComponent({
  name: '',
  props: {},
  emits: [],
  setup() {
    const data = reactive({
      form: {
        account: '',
        password: '',
        client: "WEB_CRM",
      },
      redirect: undefined,
      title: '银马科技',
      info:{
        id:'',
        roles:[]
      },
      role:'',
      modal2Visible:false
      // dependencies: dependencies,
      // devDependencies: devDependencies,
    })  //里面写所有的响应式数据，像vue2中的data一样
    // MTIzNDU2
    const router = useRouter()
    const store = useStore()
    const handleSubmit = async () => {
      console.log("登录");
      data.form.password = base1.base64_encode((data.form.password))//加密
      const res = await login(data.form)
      if (res) {
          await LocalStorageService.set(ACCESS_TOKEN, res.token)
          const info = await userInfo()
          console.log(info);
          data.info = info
          if(info.roles.length == 1){
            setRole(info.roles[0]);
          }else{
            data.modal2Visible = true
          }
          await LocalStorageService.set(USER_INFO, info)
      }
    }
    const setRole = async (roles:string) => {
          LocalStorageService.set(AUTH_ROLE, roles)
          const oss = await ossToken()
          oss.path = `https://${oss.bucket}.${oss.endpoint}/`
          await LocalStorageService.set('sts', oss)
          const menus = await userMenus(data.info.id)
          await LocalStorageService.set(PERMISSION,menus)
          router.push('/home')
    }
    const modal2OnOk = () => {
      data.modal2Visible = false
      setRole(data.role);
    }
    const locale = zhCN;
    return {
      ...toRefs(data),
      handleSubmit,
      modal2OnOk,
      STATUS,
      locale
    }
  },
  components: {},
})
